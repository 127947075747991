<template>
	<div>
		<b-form-group id="input-group-0" label="地址" label-for="input-0">
			<b-form-input id="input-0" v-model="form['address']" type="text" placeholder="地址" trim></b-form-input>
		</b-form-group>
		<b-form-group id="input-group-1" label="备注" label-for="input-1">
			<b-form-textarea id="textarea-1" v-model="form['remarks']" placeholder="备注"></b-form-textarea>
		</b-form-group>
		<b-form-group id="input-group-2" label="用户编号" label-for="input-2">
			<b-form-input id="input-2" v-model="form['user_number']" type="text" placeholder="用户编号" trim></b-form-input>
		</b-form-group>
	</div>
</template>

<script>


	export default {
		
		model: {
			prop: "form",
			event: "change"
		},
		props: {
			form: {
				type: Object,
				default: () => {
					return {
						
					}
				}
			}
		},
		data(){
			return {
			}
		},
		methods: {
			// 下拉数据列表转换
			getDropList(e){
				let resultArr = [];
				if(e){
					let arr = e.split(",");
					arr.forEach(item => {
						resultArr.push({
								value: item,
						text: item
						})
					});
				}
				return resultArr;
			}
		}
	}
</script>

<style>
</style>
